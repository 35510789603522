
import { FormController, validationRules } from '@icepanel/app-form'
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'

import * as analytics from '../../helpers/analytics'
import { OrganizationModule } from '../../store'

@Component({
  components: {
    Dialog
  },
  name: 'OrganizationApiKeyCreateDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  @Ref() readonly dialog!: Dialog
  @Ref() readonly apiKeySecretRef!: any

  apiKeySecret = ''

  secretCopied = false
  secretCopyTimer?: number

  formController = new FormController({
    initialModel: {
      name: '',
      permission: 'read' as PermissionType
    },
    validationRules: {
      name: validationRules.exists,
      permission: validationRules.exists
    }
  })

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get apiKeyId () {
    return this.$queryValue('create_api_key')
  }

  get apiKey () {
    return this.organizationModule.apiKeys.find(o => o.id === this.apiKeyId)
  }

  get permissionTypes () {
    return [
      {
        icon: '$fas-eye',
        text: 'Read',
        value: 'read'
      },
      {
        icon: '$fas-pencil-alt',
        text: 'Write',
        value: 'write'
      },
      {
        icon: '$fas-cog',
        text: 'Admin',
        value: 'admin'
      }
    ]
  }

  created () {
    this.formController.submitHandler = async model => {
      const {
        apiKey,
        secret
      } = await this.organizationModule.apiKeyCreate({
        create: {
          name: model.name,
          permission: model.permission
        },
        organizationId: this.currentOrganization.id
      })

      this.apiKeySecret = secret

      analytics.organizationApiKeyCreate.track(this, {
        organizationApiKeyName: apiKey.name,
        organizationApiKeyPermission: apiKey.permission,
        organizationId: [this.currentOrganization.id]
      })

      await this.$replaceQuery({
        create_api_key: apiKey.id
      })
    }
  }

  destroyed () {
    clearTimeout(this.secretCopyTimer)
    this.secretCopyTimer = undefined
  }

  opened () {
    analytics.organizationApiKeyCreateDialog.track(this, {
      organizationId: [this.currentOrganization.id]
    })
  }

  closed () {
    this.formController.resetModel()
    this.formController.resetStatus()
    this.apiKeySecret = ''
  }

  async copySecretToClipboard () {
    await navigator.clipboard.writeText(this.apiKeySecret)
    this.apiKeySecretRef.$refs.input.select()

    this.secretCopied = true

    clearTimeout(this.secretCopyTimer)
    this.secretCopyTimer = window.setTimeout(() => {
      this.secretCopied = false
    }, 2000)
  }
}
