
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'

import * as analytics from '../../helpers/analytics'
import { OrganizationModule } from '../../store'

@Component({
  components: {
    Dialog
  },
  name: 'OrganizationApiKeyRemoveDialog'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)

  @Ref() readonly dialog!: Dialog

  error = ''
  loading = false

  get apiKeyId () {
    return this.$queryValue('remove_api_key_dialog')!
  }

  get apiKey () {
    return this.organizationModule.apiKeys.find(o => o.id === this.apiKeyId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  opened () {
    analytics.organizationApiKeyRemoveDialog.track(this, {
      organizationId: [this.currentOrganization.id]
    })
  }

  closed () {
    this.error = ''
  }

  async remove () {
    try {
      this.loading = true

      const apiKey = this.apiKey

      await this.organizationModule.apiKeyDelete({
        apiKeyId: this.apiKeyId,
        organizationId: this.currentOrganization.id
      })

      analytics.organizationApiKeyDelete.track(this, {
        organizationApiKeyName: apiKey.name,
        organizationApiKeyPermission: apiKey.permission,
        organizationId: [this.currentOrganization.id]
      })

      this.$replaceQuery({
        remove_api_key_dialog: undefined
      })
    } catch (err: any) {
      this.error = err.body ? err.body.message : err.message
    } finally {
      this.loading = false
    }
  }
}
