
import { FormController, validationRules } from '@icepanel/app-form'
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'

import * as analytics from '../../helpers/analytics'
import { OrganizationModule } from '../../store'

@Component({
  components: {
    Dialog
  },
  name: 'OrganizationApiKeyUpdateDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  @Ref() readonly dialog!: Dialog

  formController = new FormController({
    initialModel: {
      name: '',
      permission: 'read' as PermissionType
    },
    validationRules: {
      name: validationRules.exists,
      permission: validationRules.exists
    }
  })

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get apiKeyId () {
    return this.$queryValue('update_api_key_dialog')
  }

  get apiKey () {
    return this.organizationModule.apiKeys.find(o => o.id === this.apiKeyId)!
  }

  get permissionTypes () {
    return [
      {
        icon: '$fas-eye',
        text: 'Read',
        value: 'read'
      },
      {
        icon: '$fas-pencil-alt',
        text: 'Write',
        value: 'write'
      },
      {
        icon: '$fas-cog',
        text: 'Admin',
        value: 'admin'
      }
    ]
  }

  created () {
    this.formController.submitHandler = async model => {
      const apiKey = await this.organizationModule.apiKeyUpdate({
        apiKeyId: this.apiKey.id,
        organizationId: this.currentOrganization.id,
        update: {
          name: model.name,
          permission: model.permission
        }
      })

      analytics.organizationApiKeyUpdate.track(this, {
        organizationApiKeyName: apiKey.name,
        organizationApiKeyPermission: apiKey.permission,
        organizationId: [this.currentOrganization.id]
      })

      await this.$replaceQuery({
        update_api_key_dialog: undefined
      })
    }
  }

  async open () {
    if (!this.apiKey) {
      await this.organizationModule.apiKeysList(this.currentOrganization.id)
    }

    this.formController.model.name = this.apiKey.name
    this.formController.model.permission = this.apiKey.permission
  }

  opened () {
    analytics.organizationApiKeyUpdateDialog.track(this, {
      organizationId: [this.currentOrganization.id]
    })
  }

  closed () {
    this.formController.resetModel()
    this.formController.resetStatus()
  }
}
