
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'

import ApiKeyCreateDialog from '../components/api-key/create-dialog.vue'
import ApiKeyRemoveDialog from '../components/api-key/remove-dialog.vue'
import ApiKeyUpdateDialog from '../components/api-key/update-dialog.vue'
import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  components: {
    ApiKeyCreateDialog,
    ApiKeyRemoveDialog,
    ApiKeyUpdateDialog
  },
  name: 'OrganizationApiKeys'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  loading = false

  get docsUrl () {
    return 'https://docs.icepanel.io'
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get organizationUsers () {
    return this.organizationModule.organizationUsers[this.currentOrganization.id] || {}
  }

  get apiKeys () {
    return this.organizationModule.apiKeys
  }

  get headers () {
    return [
      {
        text: 'Name',
        value: 'name',
        width: '20%'
      },
      {
        sort: (a: PermissionType, b: PermissionType) => {
          const order: PermissionType[] = ['admin', 'write', 'read']
          return a === b ? 0 : order.indexOf(a) > order.indexOf(b) ? 1 : -1
        },
        text: 'Permission',
        value: 'permission',
        width: '20%'
      },
      {
        text: 'Created by',
        value: 'createdById',
        width: '20%'
      },
      {
        text: 'Created at',
        value: 'createdAt',
        width: '20%'
      },
      {
        align: 'end',
        text: 'Actions',
        value: 'actions',
        width: '20%'
      }
    ]
  }

  get permissionTypes () {
    return [
      {
        icon: '$fas-eye',
        text: 'Read',
        value: 'read'
      },
      {
        icon: '$fas-pencil-alt',
        text: 'Write',
        value: 'write'
      },
      {
        icon: '$fas-cog',
        text: 'Admin',
        value: 'admin'
      }
    ]
  }

  async mounted () {
    analytics.organizationApiKeysScreen.track(this, {
      organizationId: [this.currentOrganization.id]
    })

    await this.load()
  }

  async load () {
    try {
      this.loading = true

      await Promise.all([
        this.organizationModule.apiKeysList(this.currentOrganization.id),
        this.organizationModule.organizationUsersList(this.currentOrganization.id)
      ])
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    } finally {
      this.loading = false
    }
  }
}
